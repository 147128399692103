<template>
    <div
        class="transition-opacity duration-1000"
        :class="{ 'opacity-0': !carouselLoaded }"
    >
        <div class="aspect-[1/1] rounded-xl shadow-card">
            <Swiper
                :modules="[Thumbs]"
                :thumbs="
                    {
                        swiper: thumbnailsSwiper,
                    } as ThumbsOptions
                "
                @slide-change="onSlideChange"
                @swiper="onCarouselSwiperInit"
            >
                <SwiperSlide
                    v-for="image in carouselImages"
                    :key="image"
                    class="flex aspect-[1/1] px-10 xs:w-1/2 xs:p-0 md:w-1/3 lg:w-1/4 xl:w-1/5"
                >
                    <StoryblokImage
                        v-editable="image.vEditable"
                        :src="image.src"
                        :alt="image.alt"
                        sizes="416px md:432px xl:664px"
                        class="swiper-slide h-full w-full object-contain p-4"
                        loading="lazy"
                    />
                </SwiperSlide>
            </Swiper>
        </div>
    </div>

    <div class="mb-1 mt-3 text-center text-sm font-semibold">
        <template v-if="currentSlideVariationLabel">
            {{ currentSlideVariationLabel }}
        </template>

        <template v-else>&nbsp;</template>
    </div>

    <div
        class="relative -mx-1.5 flex items-center transition-opacity duration-1000 lg:mx-0 lg:px-8"
        :class="{ 'opacity-0': !carouselLoaded }"
    >
        <Swiper
            slides-per-view="auto"
            :watch-slides-progress="true"
            :modules="[Navigation]"
            :navigation="
                {
                    disabledClass: 'text-grind/10 pointer-events-none',
                    hiddenClass: 'hidden',
                    lockClass: 'hidden',
                    prevEl: '.swiper-button-prev',
                    nextEl: '.swiper-button-next',
                } as NavigationOptions
            "
            @swiper="onThumbsSwiperInit"
        >
            <SwiperSlide
                v-if="carouselImages.length > 1"
                v-for="image in carouselImages"
                :key="image"
                class="product-carousel-thumbnails-slide swiper-slide w-1/4 p-1.5 lg:p-2 lg:px-2.5"
            >
                <div
                    class="relative aspect-square overflow-hidden rounded-lg p-0.5 shadow-card lg:p-1"
                >
                    <StoryblokImage
                        :src="image.src"
                        :alt="image.alt ? image.alt : 'carousel image'"
                        width="130"
                        height="130"
                        class="h-full w-full object-contain"
                        loading="lazy"
                    />

                    <div
                        class="absolute inset-0 rounded-lg ring-inset transition"
                    ></div></div
            ></SwiperSlide>
        </Swiper>
        <div
            class="hidden lg:flex lg:items-center"
            v-if="carouselImages.length > 1"
        >
            <button
                aria-label="Previous"
                class="swiper-button-prev absolute left-0 z-10 text-grind transition-colors hover:text-brew"
            >
                <Icon name="chevron-left" class="text-[1.2em]" />
            </button>

            <button
                aria-label="Next"
                class="swiper-button-next absolute right-0 z-10 text-grind transition-colors hover:text-brew"
            >
                <Icon name="chevron-right" class="text-[1.2em]" />
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Thumbs } from 'swiper/modules';
import { NavigationOptions, ThumbsOptions } from 'swiper/types';

interface CarouselSlide {
    src: string;
    alt: string | null;
    variationLabel: string | null;
    variationId: number | null;
    vEditable: any;
}

let carouselSwiper: Swiper;
let thumbnailsSwiper: Swiper;
const carouselLoaded = ref(false);

// import required modules
const emit = defineEmits(['selectedProductVariation']);

const props = defineProps<{
    product: Product | DesignerDetailType;
}>();

const currentSlideIndex = ref(0);

const carouselImages = computed(() => {
    const images: CarouselSlide[] = [];

    if (props.product.image != null) {
        images.push({
            src: props.product.image.src,
            alt: props.product.image.alt,
            variationLabel: null,
            variationId: null,
            vEditable: props.product.vEditable,
        });
    }

    props.product.variations.forEach((variation) => {
        images.push({
            src: variation.image.src,
            alt: variation.image.alt,
            variationLabel: variation.name,
            variationId: variation.id,
            vEditable: variation.vEditable,
        });
    });

    return images;
});

const currentSlideVariationLabel = computed(() => {
    return carouselImages.value.length
        ? carouselImages.value[currentSlideIndex.value].variationLabel
        : null;
});

const onThumbsSwiperInit = (swiper) => {
    thumbnailsSwiper = swiper;
    carouselLoaded.value = true;
};

const onCarouselSwiperInit = (swiper) => {
    carouselSwiper = swiper;
};

const onSlideChange = (swiper) => {
    currentSlideIndex.value = swiper.realIndex;
    emit(
        'selectedProductVariation',
        carouselImages.value[currentSlideIndex.value].variationId,
    );
};

const scrollToVariation = (variation: ProductVariation | null) => {
    if (variation) {
        const index = props.product.variations.findIndex(
            (v) => v.id === variation.id,
        );
        carouselSwiper.slideTo(index + 1);
    } else {
        carouselSwiper.slideTo(0);
    }
};

watch(
    carouselImages,
    () => {
        if (carouselSwiper) {
            carouselSwiper.update();
        }
        if (thumbnailsSwiper) {
            thumbnailsSwiper.update();
        }
    },
    { deep: true },
);

defineExpose({ scrollToVariation });
</script>
